//react packages
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Prism from "prismjs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./table-state.scss";
import {
  Button,
  Tab,
  Row,
  Col,
  Card,
  Nav,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { debounce } from "lodash";
//components
import AddNewItem from "./add-item";
import Header from "../../layouts/Header";
import MergeTable from "./live-table/merge-table";
import TransferTable from "./live-table/transfer-table";
import HomeDelivery from "./HomeDelivery/home-delivery";
import OrderEmptyState from "../_emptyState/order-empty.js";
//actions and selectors
import {
  getCreateOrderSuccess,
  getCustomerList,
  getLoading,
  getOrderRelatedChangesDone,
  getOrderTypeList,
  getOrdersByOrderType,
  getTableSectionByOrderType,
  getTransferMergeChangesDone,
  getTransferedTableOrderId,
} from "../../application/selectors/indexSelector.js";
import { LoadOrderTypeByBranchId } from "../../application/actions/orderTypeAction";
import { KOTRemarkList } from "../../application/actions/KOTAction.js";
import { getSectionByBranchId } from "../../application/actions/tableAction.js";
import {
  GetWaiterListPOS,
  changeOrderStatus,
  orderDetails,
  orderRelatedChanges,
  ordersByOrderType,
  ordersByOrderTypeSuccess,
  resetOrderDetails,
  tableSectionByOrderType,
  tableSectionByOrderTypeSuccess,
  transferMergeChanges,
  transferedTableOrderId,
} from "../../application/actions/posAction.js";
import BillPrint from "../Settings/Print/bill-print.js";
import CardShimmerLoader from "../../layouts/ShimmerCard.js";
import Environment from "../../infrastructure/core/Environment.js";
import AuthContext from "../../infrastructure/core/helpers/AuthContext.js";
import { CartProvider } from "./MyCard/cartProvider.js";
import NewGuestAssigned from "./new-guest.js";
import viewOrder from "../../assets/img/icons/search-file.png";

export default function PointOfSale() {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  //fetch selected theme from localstorage
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  //fetch selected branch from localstorage
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  //selected Ordertype details
  const [orderType, setOrderType] = useState({});
  //getting all the ordertypes whether its visibility is on or off so just filtering the visible ordertypes and storing it in array
  const [visibleOrderTypes, setVisibleOrderTypes] = useState([]);
  //orderType tab state
  const [poinofSales, setPoinofSales] = useState(null);
  //set theme
  const [skin, setSkin] = useState(currentSkin);
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);

  const [orderSetting, setOrderSetting] = useState({
    tableId: "",
    orderId: "",
  });
  const [openAddItem, setOpenAddItem] = useState(false);
  const [selectedTab, setSelectedTab] = useState({
    tableData: {},
    orderData: {},
  });
  const [selectedTabSection, setSelectedTabSection] = useState("");
  const [openMergeTable, setOpenMergeTable] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [timers, setTimers] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isOrderDetailFetched, setIsOrderDetailFetched] = useState(false);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [selectedTable, setSelectedTable] = useState(Environment.defaultValue);
  const [customerFormObject, setCustomerFormObject] = useState({});
  const [isBillPrintRequested, setisBillPrintRequested] = useState(false);
  const [billPrintData, setBillPrintData] = useState(null);

  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const orderTypeList = useSelector(getOrderTypeList);
  const tableSectionByOrderTypes = useSelector(getTableSectionByOrderType);
  const orderRelatedChangesDone = useSelector(getOrderRelatedChangesDone);
  const transferTableOrderId = useSelector(getTransferedTableOrderId);
  const transferMergeChangesDone = useSelector(getTransferMergeChangesDone);
  const ordersByOrderTypeList = useSelector(getOrdersByOrderType);
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const customerListData = useSelector(getCustomerList);

  const memoizedOrderTypeList = useMemo(() => {
    if (orderTypeList !== undefined) {
      setIsDataLoaded(false);
    }
    return orderTypeList;
  }, [orderTypeList]);
  const memoizedTableSectionByOrderTypes = useMemo(() => {
    return tableSectionByOrderTypes;
  }, [tableSectionByOrderTypes]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(KOTRemarkList);
    dispatch(tableSectionByOrderTypeSuccess(undefined));
    dispatch(ordersByOrderTypeSuccess(undefined));
  }, []);
  useEffect(() => {
    if (branchData?.value) {
      setIsDataLoaded(true);
      dispatch(LoadOrderTypeByBranchId(branchData?.value));
      dispatch(GetWaiterListPOS(branchData?.value));
      dispatch(getSectionByBranchId(branchData?.value));
    }
  }, [branchData?.value]);
  useEffect(() => {
    if (memoizedOrderTypeList && memoizedOrderTypeList.length !== 0) {
      setVisibleOrderTypes(
        memoizedOrderTypeList.filter((ordertypes) => ordertypes.isVisible)
      );
      const firstVisibleOrderType =
        transferTableOrderId !== undefined
          ? memoizedOrderTypeList.find(
              (orderType) => orderType.id === transferTableOrderId
            )
          : memoizedOrderTypeList.find((ordertypes) => ordertypes.isVisible);
      setPoinofSales(`tab-${firstVisibleOrderType.id}`);
      if (firstVisibleOrderType && branchData?.value) {
        const formData = {
          orderTypeId: firstVisibleOrderType.id,
          branchId: branchData?.value,
        };
        setOrderType(firstVisibleOrderType);
        if (firstVisibleOrderType?.isTableSection === true) {
          dispatch(tableSectionByOrderType(formData));
        } else {
          dispatch(ordersByOrderType(formData));
        }
      }
      dispatch(transferedTableOrderId(undefined));
    }
  }, [memoizedOrderTypeList]);
  useEffect(() => {
    if (
      orderRelatedChangesDone &&
      memoizedOrderTypeList &&
      memoizedOrderTypeList.length !== 0 &&
      branchData?.value
    ) {
      dispatch(orderRelatedChanges(false));
      dispatch(LoadOrderTypeByBranchId(branchData?.value));
      const formData = {
        orderTypeId: orderType?.id,
        branchId: branchData?.value,
      };
      dispatch(getSectionByBranchId(branchData?.value));
      if (orderType?.isTableSection === true) {
        dispatch(tableSectionByOrderType(formData));
      } else {
        dispatch(ordersByOrderType(formData));
      }
      setIsDataLoaded(false);
    }
  }, [orderRelatedChangesDone]);
  useEffect(() => {
    if (transferMergeChangesDone) {
      dispatch(LoadOrderTypeByBranchId(branchData?.value));
      dispatch(getSectionByBranchId(branchData?.value));
      setPoinofSales(`tab-${transferTableOrderId}`);
      setOrderType(
        memoizedOrderTypeList?.find(
          (orderType) => orderType.id === transferTableOrderId
        )
      );
      setIsDataLoaded(false);
      showOrderSetting({ tableId: "", orderId: "" });
      dispatch(transferMergeChanges(false));
    }
  }, [transferMergeChangesDone]);
  // Clear timers when component unmounts
  useEffect(() => {
    // Clear existing timers
    Object.values(timers).forEach(clearInterval);
    // Set up timers for each table
    const newTimers = {};
    memoizedTableSectionByOrderTypes?.forEach((list) => {
      list?.dineInTables?.forEach((item) => {
        if (item?.tableOrders[0]?.createdOn) {
          newTimers[item.id] = setInterval(() => {
            setTimers((prevTimers) => ({
              ...prevTimers,
              [item.id]: getTimeDifference(item.tableOrders[0]?.createdOn),
            }));
          }, 1000);
        }
      });
    });
    setIsDataLoaded(false);
    // Clear timers when component unmounts or memoizedTableSectionByOrderTypes changes
    return () => {
      Object.values(newTimers).forEach(clearInterval);
    };
  }, [memoizedTableSectionByOrderTypes]);

  const handleSalesSelect = (tab) => {
    setPoinofSales(tab);
    transferedTableOrderId(undefined);
  };
  const showOrderSetting = (tableId, orderId) => {
    setOrderSetting({ tableId: tableId, orderId: orderId });
  };
  const closeOrderSetting = () => {
    setOrderSetting({ tableId: "", orderId: "" });
  };
  const handleAddItem = (tableData, state, customerFormObject) => {
    dispatch(resetOrderDetails);
    setIsNewOrder(false);
    setOpenAddItem(state);
    setSelectedTab({ tableData: tableData, orderData: {} });
    setCustomerFormObject(customerFormObject);
  };
  const handleMergeTable = (table, state) => {
    setOpenMergeTable(state);
    setSelectedTable(table);
  };
  const handleTransferModal = (state) => {
    setShowTransferModal(state);
  };
  const OrderTypeTabClick = (type) => {
    setOrderType(type);
    setIsDataLoaded(true);
    const formData = {
      orderTypeId: type?.id,
      branchId: branchData?.value,
    };
    if (type?.isTableSection === true) {
      dispatch(tableSectionByOrderType(formData));
    } else {
      dispatch(ordersByOrderType(formData));
    }
    setIsDataLoaded(false);
  };
  const handleOrderDetails = (
    orderId,
    orderDetail,
    tableData,
    state,
    section
  ) => {
    setIsOrderDetailFetched(true);
    dispatch(orderDetails(orderId));
    setOpenAddItem(state);
    setSelectedTab({ tableData: tableData, orderData: orderDetail });
    setIsOrderDetailFetched(false);
    setSelectedTabSection(section?.name);
  };
  // Timer function
  const getTimeDifference = (createdOn) => {
    const currentDate = new Date();
    const createdDateTime = new Date(createdOn);
    const difference = currentDate - createdDateTime;
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };
  const handleNewOrder = (tableData, state, section) => {
    setIsNewOrder(state);
    setSelectedTab({ tableData: tableData, orderData: {} });
    setSelectedTabSection(section?.name);
  };
  const handleBillPrintRequest = (
    tableData,
    sectionName,
    createOrderSuccess
  ) => {
    // setIsDataLoaded(true);
    const tableName = tableData?.tableName;
    const selectedCustomerData = customerListData?.find(
      (customerData) => customerData.id === createOrderSuccess.customerId
    );
    const customer = {
      fullName: selectedCustomerData?.fullName,
      id: selectedCustomerData?.id,
    };
    if (orderType?.isTableSection) {
      const formObject = {
        salesId: createOrderSuccess?.id,
        orderStatus: "a5ca48b8-bfd1-42d6-8db1-7021c131e606",
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(changeOrderStatus(formObjectData));
    }
    setBillPrintData({
      createOrderSuccess,
      tableName,
      sectionName,
      orderType,
      customer,
    });
    setisBillPrintRequested(true);
  };
  const addNewItemComponent = useMemo(() => {
    // If loading order details or order detail is fetched, show loader
    if ((loading && value === "orderDetails") || isOrderDetailFetched) {
      return;
    }
    // If createOrderSuccess is undefined and not starting a new order, return null
    if (createOrderSuccess === undefined && isOrderDetailFetched) {
      return null;
    }
    // If createOrderSuccess is defined or starting a new order, show AddNewItem
    return (
      <CartProvider>
        <AddNewItem
          show={openAddItem}
          selectedTab={selectedTab}
          orderType={orderType}
          closeFunction={handleAddItem}
          orderTypeList={visibleOrderTypes}
          sectionByOrderTypeId={memoizedTableSectionByOrderTypes}
          selectedTabSection={selectedTabSection}
          customerFormObject={customerFormObject}
        />
      </CartProvider>
    );
  }, [
    openAddItem,
    selectedTab,
    orderType,
    visibleOrderTypes,
    memoizedTableSectionByOrderTypes,
    loading,
    value,
    createOrderSuccess,
    isOrderDetailFetched,
  ]);
  const newGuest = useMemo(
    () => (
      <NewGuestAssigned
        show={isNewOrder}
        closeFunction={handleNewOrder}
        handleAddItem={handleAddItem}
        selectedTab={selectedTab}
        selectedTabSection={selectedTabSection}
      />
    ),
    [isNewOrder]
  );
  const mergeTableComponent = useMemo(
    () => (
      <MergeTable
        show={openMergeTable}
        closeFunction={handleMergeTable}
        sectionByOrderTypeId={memoizedTableSectionByOrderTypes}
        selectedTable={selectedTable}
        setIsDataLoaded={setIsDataLoaded}
        orderType={orderType}
      />
    ),
    [openMergeTable]
  );
  const transferTableComponent = useMemo(
    () => (
      <TransferTable
        show={showTransferModal}
        closeFunction={handleTransferModal}
        orderTypeList={visibleOrderTypes}
        orderDetails={selectedTab?.orderData}
        setIsDataLoaded={setIsDataLoaded}
        orderType={orderType}
        selectedTab={selectedTab?.tableData}
      />
    ),
    [
      showTransferModal,
      visibleOrderTypes,
      memoizedTableSectionByOrderTypes,
      selectedTab,
    ]
  );

  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);

  return (
    <React.Fragment>
      <Header
        onSkin={setSkin}
        passDataToPointOfSale={handleBranchIdFromHeader}
      />

      <div className="main main-app">
        {addNewItemComponent} {mergeTableComponent} {transferTableComponent}{" "}
        {newGuest}
        <div className="main-category-tabs-wrapper sticky-main-category">
          <>
            {visibleOrderTypes?.map((item) => (
              <>
                {item?.isVisible === true && (
                  <Nav
                    variant="pills"
                    className="sale-on-point-tabs table-state-tabs bg-transparent"
                    key={item.id}
                    activeKey={poinofSales}
                    onClick={() => OrderTypeTabClick(item)}
                    eventKey={`tab-${item.id}`}
                    onSelect={handleSalesSelect}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={`tab-${item.id}`} className="rounded-0" title={item.typeName}>
                        {" "}
                        {item.typeName}{" "}
                        {item.orderCount !== 0 && (<span className="badge rounded-pill badge-length-web">{item.orderCount}</span>)}
                        
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}
              </>
            ))}
          </>
        </div>
        <>
          <Tab.Content className="pe-3 ps-3 pb-3">
            {orderType && orderType?.isTableSection === false ? (
              <>
                <div className="custom-tab-body mt-3 pb-2">
                  {(loading &&
                    (value === "ordersByOrderType" ||
                      value === "loadOrderType" ||
                      value === "mergeTable" ||
                      value === "transferOrder" ||
                      value === "tableSectionByOrderType" ||
                      value === "orderDetails" ||
                      isOrderDetailFetched)) ||
                  ordersByOrderTypeList === undefined ||
                  isDataLoaded ||
                  orderRelatedChangesDone ? (
                    <CardShimmerLoader columnCount={4} rowCount={4} />
                  ) : (
                    <>
                      <HomeDelivery
                        handleAddItem={handleAddItem}
                        orderType={orderType}
                        handleOrderDetails={handleOrderDetails}
                        handleBillPrintRequest={handleBillPrintRequest}
                        selectedTabSection={selectedTabSection}
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="custom-tab-body mt-3 pb-2">
                  {(loading &&
                    (value === "sectionList" ||
                      value === "loadOrderType" ||
                      value === "mergeTable" ||
                      value === "transferOrder" ||
                      value === "tableSectionByOrderType" ||
                      value === "orderDetails" ||
                      isOrderDetailFetched)) ||
                  memoizedTableSectionByOrderTypes === undefined ||
                  orderRelatedChangesDone ||
                  isDataLoaded ? (
                    <CardShimmerLoader columnCount={4} rowCount={4} />
                  ) : (
                    <>
                      {memoizedTableSectionByOrderTypes &&
                        (memoizedTableSectionByOrderTypes?.length !== 0 ? (
                          <>
                            {memoizedTableSectionByOrderTypes?.map(
                              (list, index) => (
                                <>
                                  {(list?.dineInTables?.filter(
                                    (section) => section.isMerged === false
                                  )).length !== 0 && (
                                    <div
                                      className="table-section-wise mb-3"
                                      key={index}
                                    >
                                      <div className="divider divider-start table-section-header">
                                        <span className="fs-14 fw-semibold text-dark">
                                          {list.name}
                                        </span>
                                      </div>

                                      {list?.dineInTables &&
                                      list?.dineInTables?.length !== 0 ? (
                                        <>
                                          <Row className="g-3">
                                            {list?.dineInTables?.map(
                                              (item, secondIndex) => (
                                                <>
                                                  {item?.tableOrders &&
                                                  item?.tableOrders?.length ===
                                                    0 ? (
                                                    <>
                                                      <Col
                                                        xl={2}
                                                        lg={4}
                                                        md={6}
                                                        sm={12}
                                                        className="col-custom-width"
                                                        key={secondIndex}
                                                      >
                                                        {item?.isMerged ===
                                                          false && (
                                                          <Card
                                                            className={`live-table-card card-one border-success status-success shadow-none`}
                                                          >
                                                            <Card.Body>
                                                              <div className="table-header d-flex align-items-center justify-content-between">
                                                                <OverlayTrigger
                                                                  overlay={
                                                                    <Tooltip
                                                                      id={`tooltip-top`}
                                                                    >
                                                                      {item
                                                                        .mergedTables
                                                                        .length >
                                                                      0 ? (
                                                                        <>
                                                                          {
                                                                            item.tableName
                                                                          }{" "}
                                                                          -{" "}
                                                                          {item.mergedTables
                                                                            .map(
                                                                              (
                                                                                mergedTable
                                                                              ) =>
                                                                                mergedTable.tableName
                                                                            )
                                                                            .join(
                                                                              " - "
                                                                            )}
                                                                        </>
                                                                      ) : (
                                                                        item.tableName
                                                                      )}
                                                                    </Tooltip>
                                                                  }
                                                                  showLabel={
                                                                    "Show"
                                                                  }
                                                                  hideLabel={
                                                                    "Hide"
                                                                  }
                                                                >
                                                                  <h3 className="text-dark fs-18 mb-0 fw-bold text-capitalize me-2 table-name-text">
                                                                    {item
                                                                      .mergedTables
                                                                      .length >
                                                                    0 ? (
                                                                      <>
                                                                        {
                                                                          item.tableName
                                                                        }{" "}
                                                                        -{" "}
                                                                        {item.mergedTables
                                                                          .map(
                                                                            (
                                                                              mergedTable
                                                                            ) =>
                                                                              mergedTable.tableName
                                                                          )
                                                                          .join(
                                                                            " - "
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      item.tableName
                                                                    )}
                                                                  </h3>
                                                                </OverlayTrigger>
                                                              </div>
                                                              <div className="table-footer">
                                                                <div
                                                                  className={`add-item-bttn`}
                                                                >
                                                                  <Button
                                                                    variant="success"
                                                                    className="gap-1"
                                                                    onClick={() =>
                                                                      handleNewOrder(
                                                                        item,
                                                                        true,
                                                                        list
                                                                      )
                                                                    }
                                                                  >
                                                                    <i className="ri-add-line fs-16"></i>
                                                                    <span className="d-sm-inline fs-14">
                                                                      Start
                                                                    </span>
                                                                  </Button>
                                                                </div>
                                                              </div>
                                                            </Card.Body>
                                                          </Card>
                                                        )}
                                                      </Col>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item.tableOrders?.map(
                                                        (
                                                          orderDetail,
                                                          orderIndex
                                                        ) => (
                                                          <Col
                                                            xl={2}
                                                            lg={4}
                                                            md={6}
                                                            sm={12}
                                                            className="col-custom-width"
                                                            key={orderIndex}
                                                          >
                                                            {item?.isMerged ===
                                                              false && (
                                                              <Card
                                                                className={`live-table-card card-one shadow-none ${
                                                                  item?.tableStatus ===
                                                                    "d9c5a952-911e-4a0f-8935-5e1fae02402e" &&
                                                                  orderDetail?.length !==
                                                                    0 &&
                                                                  orderDetail?.orderStatus ===
                                                                    "a5ca48b8-bfd1-42d6-8db1-7021c131e606"
                                                                    ? "border-primary status-primary"
                                                                    : item
                                                                        ?.mergedTables
                                                                        ?.length >
                                                                      0
                                                                    ? "border-warning status-warning"
                                                                    : item?.tableStatus ===
                                                                        "d9c5a952-911e-4a0f-8935-5e1fae02402e" &&
                                                                      orderDetail.length !==
                                                                        0 &&
                                                                      orderDetail?.orderStatus ===
                                                                        "a5ca48b8-bfd1-42d6-8db1-7021c131e606"
                                                                    ? "border-primary status-primary"
                                                                    : item?.tableStatus ===
                                                                        "d9c5a952-911e-4a0f-8935-5e1fae02402e" &&
                                                                      orderDetail?.length !==
                                                                        0 &&
                                                                      orderDetail?.orderStatus ===
                                                                        "a5ca48b8-bfd1-42d6-8db1-7021c131e601"
                                                                    ? "border-danger status-danger"
                                                                    : item?.tableStatus ===
                                                                        "d9c5a952-911e-4a0f-8935-5e1fae02401e" &&
                                                                      orderDetail?.length ===
                                                                        0
                                                                    ? "border-success status-success"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <Card.Body>
                                                                  <div className="table-header d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                      <Link
                                                                        className={`text-dark me-2 fw-bold ${
                                                                          item.tableStatus ===
                                                                          "d9c5a952-911e-4a0f-8935-5e1fae02401e"
                                                                            ? "d-none"
                                                                            : ""
                                                                        }`}
                                                                        onClick={() =>
                                                                          showOrderSetting(
                                                                            item.id,
                                                                            orderDetail?.id
                                                                          )
                                                                        }
                                                                      >
                                                                        <i className="ri-menu-line fs-18"></i>
                                                                      </Link>
                                                                      <OverlayTrigger
                                                                        overlay={
                                                                          <Tooltip
                                                                            id={`tooltip-top`}
                                                                          >
                                                                            {item
                                                                              .mergedTables
                                                                              .length >
                                                                            0 ? (
                                                                              <>
                                                                                {
                                                                                  item.tableName
                                                                                }{" "}
                                                                                -{" "}
                                                                                {item.mergedTables
                                                                                  .map(
                                                                                    (
                                                                                      mergedTable
                                                                                    ) =>
                                                                                      mergedTable.tableName
                                                                                  )
                                                                                  .join(
                                                                                    " - "
                                                                                  )}
                                                                              </>
                                                                            ) : (
                                                                              item.tableName
                                                                            )}
                                                                          </Tooltip>
                                                                        }
                                                                        showLabel={
                                                                          "Show"
                                                                        }
                                                                        hideLabel={
                                                                          "Hide"
                                                                        }
                                                                      >
                                                                        <h3 className="text-dark fs-18 mb-0 fw-bold text-capitalize me-1 table-name-text wt-100">
                                                                          {item
                                                                            .mergedTables
                                                                            .length >
                                                                          0 ? (
                                                                            <>
                                                                              {
                                                                                item.tableName
                                                                              }{" "}
                                                                              -{" "}
                                                                              {item.mergedTables
                                                                                .map(
                                                                                  (
                                                                                    mergedTable
                                                                                  ) =>
                                                                                    mergedTable.tableName
                                                                                )
                                                                                .join(
                                                                                  " - "
                                                                                )}
                                                                            </>
                                                                          ) : (
                                                                            item.tableName
                                                                          )}
                                                                        </h3>
                                                                      </OverlayTrigger>
                                                                    </div>

                                                                    {item?.tableStatus !==
                                                                      "d9c5a952-911e-4a0f-8935-5e1fae02401e" &&
                                                                      orderDetail?.length !==
                                                                        0 && (
                                                                        <h3 className="text-dark fs-12 d-flex align-items-center fw-bold">
                                                                          <i className="ri-time-line me-1 fs-16"></i>{" "}
                                                                          {timers[
                                                                            item
                                                                              .id
                                                                          ] ||
                                                                            "00:00"}
                                                                        </h3>
                                                                      )}
                                                                  </div>

                                                                  <div className="table-body">
                                                                    <h4
                                                                      className={`mb-0 fs-12 text-dark align-items-center 
                                                                    ${
                                                                      item?.tableStatus ===
                                                                        "d9c5a952-911e-4a0f-8935-5e1fae02401e" &&
                                                                      orderDetail?.length ===
                                                                        0
                                                                        ? "d-none"
                                                                        : ""
                                                                    }  `}
                                                                    >
                                                                      {orderDetail?.waiterDetails !==
                                                                        null &&
                                                                        orderDetail
                                                                          ?.waiterDetails
                                                                          .id !==
                                                                          "00000000-0000-0000-0000-000000000000" && (
                                                                          <i className="ri-user-2-line fs-14 align-middle"></i>
                                                                        )}
                                                                      <span className="align-middle fw-semibold">
                                                                        {orderDetail?.waiterDetails !==
                                                                          null &&
                                                                          orderDetail
                                                                            ?.waiterDetails
                                                                            ?.fullName}{" "}
                                                                      </span>
                                                                    </h4>
                                                                  </div>

                                                                  <div className="table-footer">
                                                                    {item?.tableStatus ===
                                                                      "d9c5a952-911e-4a0f-8935-5e1fae02401e" &&
                                                                    orderDetail?.length ===
                                                                      0 ? (
                                                                      <div
                                                                        className={`add-item-bttn`}
                                                                      >
                                                                        <Button
                                                                          variant="success"
                                                                          className="gap-1"
                                                                          onClick={() =>
                                                                            handleNewOrder(
                                                                              item,
                                                                              true,
                                                                              list
                                                                            )
                                                                          }
                                                                        >
                                                                          <i className="ri-add-line fs-16"></i>
                                                                          <span className="d-sm-inline fs-14">
                                                                            Start
                                                                          </span>
                                                                        </Button>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        className={`table-footer-content align-items-center d-flex justify-content-between lh-normal`}
                                                                      >
                                                                        {/* <h4 className={`dish-price fs-16 fw-semibold mb-0 lh-normal 
                                                                          ${orderDetail?.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e702" ? "text-danger" : 
                                                                          orderDetail?.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" ? "text-success" : ""
                                                                          }`}
                                                                      >
                                                                        {parsedBranchData?.currencySymbol}{" "}
                                                                        {orderDetail?.grandTotalPrice}
                                                                      </h4> */}

                                                                        <h4
                                                                          className={`dish-price text-dark fs-16 fw-semibold mb-0 lh-normal 
                                                                          ${
                                                                            orderDetail?.paymentStatus ===
                                                                            "a5ca48b8-bfd1-42d6-8db1-7021c131e702"
                                                                              ? "unpaid-class"
                                                                              : orderDetail?.paymentStatus ===
                                                                                "a5ca48b8-bfd1-42d6-8db1-7021c131e701"
                                                                              ? "paid-class"
                                                                              : ""
                                                                          }`}
                                                                        >
                                                                          {
                                                                            parsedBranchData?.currencySymbol
                                                                          }{" "}
                                                                          {
                                                                            orderDetail?.grandTotalPrice
                                                                          }
                                                                          <Badge
                                                                            bg="success"
                                                                            className="fs-10 badge-pill ms-1 text-uppercase"
                                                                          >
                                                                            Paid
                                                                          </Badge>
                                                                        </h4>
                                                                        <div className="d-flex align-items-center">
                                                                          <Button
                                                                            variant="white"
                                                                            className="text-dark fw-medium border-0 p-0 me-2"
                                                                            onClick={() =>
                                                                              handleBillPrintRequest(
                                                                                item,
                                                                                list?.name,
                                                                                orderDetail
                                                                              )
                                                                            }
                                                                          >
                                                                            <i className="ri-printer-line fs-22"></i>
                                                                          </Button>

                                                                          <Link
                                                                            className="text-dark fw-medium"
                                                                            onClick={() =>
                                                                              handleOrderDetails(
                                                                                orderDetail?.id,
                                                                                orderDetail,
                                                                                item,
                                                                                true,
                                                                                list
                                                                              )
                                                                            }
                                                                          >
                                                                            {/* <i className="ri-eye-line fs-22"></i> */}
                                                                            <img
                                                                              src={
                                                                                viewOrder
                                                                              }
                                                                              className="wt-22"
                                                                              alt="view"
                                                                            />
                                                                          </Link>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </Card.Body>

                                                                <div
                                                                  key={item.id}
                                                                  className={`order-setting ${
                                                                    orderSetting.tableId ===
                                                                      item.id &&
                                                                    orderSetting.orderId ===
                                                                      orderDetail?.id
                                                                      ? "open"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <Link
                                                                    className="order-setting-close text-dark"
                                                                    onClick={
                                                                      closeOrderSetting
                                                                    }
                                                                  >
                                                                    <i className="ri-close-line fs-20"></i>
                                                                  </Link>
                                                                  <div className="order-setting-btn">
                                                                    <Button
                                                                      type="button"
                                                                      variant="outline-dark fs-12"
                                                                      disabled={
                                                                        item
                                                                          .mergedTables
                                                                          .length ===
                                                                          0 &&
                                                                        item
                                                                          ?.tableOrders
                                                                          ?.length >
                                                                          1
                                                                      }
                                                                      onClick={() =>
                                                                        item
                                                                          .mergedTables
                                                                          .length ===
                                                                          0 &&
                                                                        item
                                                                          ?.tableOrders
                                                                          ?.length >
                                                                          1
                                                                          ? ""
                                                                          : handleMergeTable(
                                                                              item,
                                                                              true
                                                                            )
                                                                      }
                                                                    >
                                                                      <i className="ri-merge-cells-horizontal fs-14"></i>{" "}
                                                                      Merge
                                                                    </Button>
                                                                    <Button
                                                                      type="button"
                                                                      variant="outline-dark fs-12"
                                                                      onClick={() => {
                                                                        setSelectedTab(
                                                                          {
                                                                            tableData:
                                                                              item,
                                                                            orderData:
                                                                              orderDetail,
                                                                          }
                                                                        );
                                                                        handleTransferModal(
                                                                          true
                                                                        );
                                                                      }}
                                                                    >
                                                                      <i className="ri-arrow-left-right-line fs-14"></i>{" "}
                                                                      Transfer
                                                                    </Button>
                                                                    <Button
                                                                      type="button"
                                                                      variant="success fs-12 text-white fw-bold"
                                                                      disabled={
                                                                        item
                                                                          .mergedTables
                                                                          .length !==
                                                                        0
                                                                      }
                                                                      onClick={() =>
                                                                        item
                                                                          .mergedTables
                                                                          .length ===
                                                                        0
                                                                          ? handleNewOrder(
                                                                              item,
                                                                              true,
                                                                              list
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <i className="ri-add-line fs-16"></i>{" "}
                                                                      Start New
                                                                    </Button>
                                                                  </div>
                                                                </div>
                                                              </Card>
                                                            )}
                                                          </Col>
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </Row>
                                        </>
                                      ) : (
                                        <>
                                          <OrderEmptyState />
                                        </>
                                      )}
                                    </div>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <OrderEmptyState />
                          </>
                        ))}
                    </>
                  )}
                </div>
              </>
            )}
          </Tab.Content>
        </>
      </div>
      {isBillPrintRequested && (
        <BillPrint
          billPrintData={billPrintData}
          onBillPrintRequested={() => setisBillPrintRequested(false)}
        />
      )}
    </React.Fragment>
  );
}
