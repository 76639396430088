import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getMenuList,
} from "../../../application/selectors/indexSelector";
import {
  GetCategoryByMainCatId,
  GetMenuList,
} from "../../../application/actions/menuAction";
import { CreateItemCatalog } from "../../../application/actions/ItemAction";
import { Toaster } from "react-hot-toast";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { loadTax } from "../../../application/actions/loaderAction";
import Environment from "../../../infrastructure/core/Environment";
import {
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";

import Select from "react-select";
import axios from "axios";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function AddNewCatalogue(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const MenuList = useSelector(getMenuList);
  const Category = useSelector(getCategoryList);
  const FoodType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FOODTY"
  );
  const FoodSubType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FUDSUBTY"
  );
  const [showSubTyppe, setShowSubTyppe] = useState(false);
  useEffect(() => {
    dispatch(GetMenuList);
    dispatch(loadTax);
  }, []);
  const initialState = {
    catalogueName: "",
    catalogueCode: "",
    description: "",
    longDesc: "",
    mainCatId: `${Environment.defaultValue}`,
    catId: Category ? Category[0]?.id : `${Environment.defaultValue}`,
    foodtype: `${Environment.defaultValue}`,
    foodSubType: `${Environment.defaultValue}`,
    isTaxFree: false,
    isKOT: true,
    isDaySpecial: false,
    CreatedBy: user.id,
    ModifiedBy: user.id,
    status: "9251e44c-dda2-40d9-8d96-b392d974331b",
    visibility: "73649d84-d590-40b9-8971-28b830acacd6",
    catalogueOnDays: [],
  };
  const [formData, setFormData] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  useEffect(() => {
    if (props.data) {
      props.data.catalogue.foodType === "a634316f-857d-41fd-a74c-895f61e4de65"
        ? setShowSubTyppe(true)
        : setShowSubTyppe(false);
      setFormData({
        ...formData,
        catalogueName: props.data.catalogue.catalogueName,
        catalogueCode: props.data.catalogue.catalogueCode,
        description: props.data.catalogue.description,
        longDesc: props.data.catalogue.longDesc,
        mainCatId: props.data.catalogue.mainCatId,
        catId: props.data.catalogue.catId,
        foodtype: props.data.catalogue.foodType,
        foodSubType: props.data.catalogue.foodSubType,
        isTaxFree: props.data.catalogueFeatures.isTaxFree,
        isKOT: props.data.catalogueFeatures.isKOT,
        isDaySpecial: props.data.catalogueFeatures.isDaySpecial,
        catalogueOnDays: props.data.catalogueFeatures.catalogueOnDays,
        CreatedBy: user.id,
        ModifiedBy: user.id,
        status: "9251e44c-dda2-40d9-8d96-b392d974331b",
        visibility: "73649d84-d590-40b9-8971-28b830acacd6",
      });
      dispatch(GetCategoryByMainCatId(props.data.catalogue.mainCatId));
    }
  }, [props.data]);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.catalogueName = validateRequired(formData.catalogueName)
      ? ""
      : "Catalogue Name is required";

    // Validate catalogueCode
    newErrors.catalogueCode = validateRequired(formData.catalogueCode)
      ? ""
      : "Short Code is required";

    // Validate mainCatId (dropdown)
    newErrors.mainCatId = validateRequiredDropdown(formData.mainCatId)
      ? ""
      : "Please Select Main Menu";

    // Validate catId (dropdown)
    newErrors.catId = validateRequiredDropdown(formData.catId)
      ? ""
      : "Please Select Sub Menu";
    // Validate foodtype (dropdown)
    newErrors.foodtype = validateRequiredDropdown(formData.foodtype)
      ? ""
      : "Please Select Food Type";
    if (formData.foodtype === "a634316f-857d-41fd-a74c-895f61e4de65") {
      // Validate foodSubType (dropdown)
      newErrors.foodSubType = validateRequiredDropdown(formData.foodSubType)
        ? ""
        : "Please Select Food Sub Type";
    }
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const codeExists = props?.CatalogueCodes.includes(formData.catalogueCode);
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    if (codeExists && formData.catalogueCode) {
      setErrors({ ...errors, catalogueCode: "Item Short Code Already Exist" });
    } else {
      setErrors({ ...errors, catalogueCode: "" });
      // Dispatch action to create item catalogue

      const selectedValues = formData?.catalogueOnDays?.map(
        (days) => days.value
      );
      formData.catalogueOnDays = selectedValues;
      dispatch(CreateItemCatalog(formData));

      // Reset form data and close the form
      setFormData(initialState);
      props.closeFunction();
    }
  };
  const handleMainCat = (id) => {
    dispatch(GetCategoryByMainCatId(id));
    setFormData({
      ...formData,
      mainCatId: id,
      catId: Environment.defaultValue,
    });
    setErrors((prevErrors) => ({ ...prevErrors, mainCatId: "" }));
  };
  const handleCodeExist = (code) => {
    const codeExists = props?.CatalogueCodes.includes(code);
    return codeExists;
  };
  const handleFoodType = (e) => {
    if (e.target.value === "a634316f-857d-41fd-a74c-895f61e4de65") {
      setShowSubTyppe(true);
    } else {
      setShowSubTyppe(false);
    }
    setFormData({
      ...formData,
      foodtype: e.target.value,
      foodSubType: `${Environment.defaultValue}`,
    });
  };
  const generateShortCode = (name) => {
    const words = name.split(" ");
    // Extract the first letter of each word
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    // Determine the shortcode based on the number of words
    if (words.length === 1) {
      // For a single-word name like "redPop"
      return words[0]?.slice(0, 3).toUpperCase();
    } else if (words.length === 2) {
      // For a two-word name like "redPop Velvet"
      const firstWordInitials = words[0]?.slice(0, 2).toUpperCase(); // Take 2 letters from the first word
      const secondWordInitial = words[1]?.charAt(0).toUpperCase(); // Take 1 letter from the second word
      return firstWordInitials + secondWordInitial;
    } else {
      return initials.join("").toUpperCase();
    }
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    if (name === "") {
      setFormData({ ...formData, catalogueName: name, catalogueCode: "" });
    } else {
      const shortCode = generateShortCode(name);
      setFormData({ ...formData, catalogueName: name });
      const Exist = handleCodeExist(shortCode);
      if (Exist) {
        let newCode = shortCode;
        let counter = 1;
        // Keep generating a new code by appending a number until it's unique
        while (handleCodeExist(newCode)) {
          newCode = `${shortCode}_${counter}`; // Append a number to the original code
          counter++;
        }
        setFormData({
          ...formData,
          catalogueCode: newCode,
          catalogueName: name,
        });
        // Set the new unique code as the value for the catalogueCode field
      } else {
        // Code doesn't exist or is empty, clear error messages
        setFormData({
          ...formData,
          catalogueCode: shortCode,
          catalogueName: name,
        });
        setErrors({ ...errors, catalogueCode: "", catalogueName: "" });
        // Set the code directly if it's valid and doesn't exist
        // setCode(code);
      }
    }
  };
  const handleCatalogCode = (shortCode, name) => {
    const Exist = handleCodeExist(shortCode);
    const code = shortCode.split("_");
    if (Exist) {
      let newCode = code[0];
      let counter = code[1];
      // Keep generating a new code by appending a number until it's unique
      while (handleCodeExist(newCode)) {
        newCode = `${code[0]}_${counter}`; // Append a number to the original code
        counter++;
      }
      setFormData({ ...formData, catalogueCode: newCode, catalogueName: name });
      // Set the new unique code as the value for the catalogueCode field
    } else {
      // Code doesn't exist or is empty, clear error messages
      setFormData({
        ...formData,
        catalogueCode: shortCode,
        catalogueName: name,
      });
      setErrors({ ...errors, catalogueCode: "", catalogueName: "" });
      // Set the code directly if it's valid and doesn't exist
      // setCode(code);
    }
  };
  const handleClose = () => {
    setFormData(initialState);
    props.closeFunction();
  };

  const selectDaysOptions = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];
  const [aiLoader1, setAILoader1] = useState(false);
  const [buttonDisabled1, setButtonDisabled1] = useState(false);
  const [aiLoader2, setAILoader2] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);

  const generateContent = async (words) => {
    const question = `You are a marketing specialist. Your task is to give a short description based on the dish name.
    dish_Name: ${formData.catalogueName}
       {
        dishDescription:''}
        - Description must be focused to intrigue the end customer.
        - Description should be limited to ${words} characters.
        - The JSON schema must be followed .
        - Response should not include information outside the JSON schema
        - Description should includ the ingredients of the dish`;
    const form = new FormData();
    // formData.append("file", selectedFile);
    form.append("question", question);
    try {
      setAILoader1(true);
      const res = await axios.post(
        "https://gatewaypy.fudeasy.com/ask_Question",
        form,
        {
          // const res = await axios.post('', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Clean up the response if necessary
      let extractedData = res.data.extracted_data;
      console.log(extractedData);
      if (typeof extractedData === "string") {
        extractedData = extractedData.replace("json", "");
        extractedData = extractedData.replace("```", "");
        extractedData = extractedData.replace("```", "");
        console.log(extractedData);
        extractedData = JSON.parse(extractedData);
        console.log(extractedData);
      }
      setFormData({ ...formData, description: extractedData.dishDescription });
      setAILoader1(false);
      setButtonDisabled1(true);
      setTimeout(() => {
        setButtonDisabled1(false);
      }, 60000); // 1 minute in milliseconds
    } catch (err) {
      console.error(err);
      setAILoader1(false);
    }
  };
  const generateContentIngredient = async (words) => {
    const question = `
      You are a marketing specialist. Your task is to identify history , importance and an interesting story of a dish. If the origin is unclear then call it is our signature dish.
     Dish Name: ${formData.catalogueName};
     {
      History:""
      }
      - Description should be limited to ${words} characters
      -The JSON schema must be followed .
      - Do not use the word unclear or anything related just call it signature dish.
      - Do not provide false information
      - If the origin is unclear then call it is our signature dish.
      - Response should not include information outside the JSON schema`;
    const form = new FormData();
    // formData.append("file", selectedFile);
    form.append("question", question);
    try {
      setAILoader2(true);
      const res = await axios.post(
        "https://gatewaypy.fudeasy.com/ask_Question",
        form,
        {
          // const res = await axios.post('', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Clean up the response if necessary
      let extractedData = res.data.extracted_data;
      if (typeof extractedData === "string") {
        extractedData = extractedData.replace("json", "");
        extractedData = extractedData.replace("```", "");
        extractedData = extractedData.replace("```", "");
        extractedData = JSON.parse(extractedData);
        console.log(extractedData);
      }
      setFormData({ ...formData, longDesc: extractedData.History });
      setAILoader2(false);
      setButtonDisabled2(true);
      setTimeout(() => {
        setButtonDisabled2(false);
      }, 60000); // 1 minute in milliseconds
    } catch (err) {
      setAILoader2(false);
      console.error(err);
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create New Item</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-3">
                <Form.Label 
                className="fs-12 fw-semibold">
                  Item Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  className="fw-semibold text-dark"
                  name="catalogueName"
                  placeholder="Enter Item Name"
                  value={formData.catalogueName}
                  onChange={handleNameChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                  }}
                  required
                />
                <Form.Text className="text-danger">
                  {errors.catalogueName}
                </Form.Text>
              </div>
            </Col>
            {/* setFormData({ ...formData, catalogueName: e.target.value }); */}

            <Col xl="6">
              <div className="mb-3">
                <Form.Label className="fs-12 fw-semibold">
                  Item Short Code: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  className="text-dark"
                  placeholder="e.g. CHK65"
                  name="catalogueCode"
                  value={formData.catalogueCode}
                  onChange={(e) => {
                    setFormData({ ...formData, catalogueCode: e.target.value });
                  }}
                  onBlur={() =>
                    handleCatalogCode(
                      formData.catalogueCode,
                      formData.catalogueName
                    )
                  }
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.catalogueCode}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <div className="mb-3">
                <Form.Label className="fs-12 fw-semibold">Short Description:</Form.Label>
                <Form.Control
                  type="text"
                   className="text-dark"
                  size="sm"
                  placeholder="Enter Short Description"
                  value={formData.description}
                  name="description"
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mt-2 magic-bttn"
                  onClick={() => generateContent(100)}
                  disabled={aiLoader1 || buttonDisabled1}
                >
                  {aiLoader1 ? (
                    <LoadingSpinner size={30} type={"TailSpin"} />
                  ) : (
                    <>
                      <i className="ri-magic-line align-middle me-1"></i>
                      <span className="align-middle fs-12">Generate</span>
                    </>
                  )}
                </Button>
              </div>
            </Col>
            <Col xl="12">
              <div className="mb-3">
                <Form.Label className="fs-12 fw-semibold">Long Description:</Form.Label>
                <Form.Control
                  as="textarea"
                  size="sm"
                  rows="3"
                   className="text-dark"
                  placeholder="Enter Long Description"
                  name="longDesc"
                  value={formData.longDesc}
                  onChange={(e) => {
                    setFormData({ ...formData, longDesc: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
                <Button
                  variant="outline-primary"
                   size="sm"
                  className="mt-2 magic-bttn"
                  onClick={() => generateContentIngredient(500)}
                  disabled={aiLoader2 || buttonDisabled2}
                >
                  {aiLoader2 ? (
                    <LoadingSpinner size={30} type={"TailSpin"} />
                  ) : (
                    <>
                      <i className="ri-magic-line align-middle me-1"></i>
                      <span className="align-middle fs-12">Generate</span>
                    </>
                  )}
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-3">
                <Form.Label className="fs-12 fw-semibold">
                  Main Menu: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                   size="sm"
                    className="text-dark"
                  aria-label="Default select example"
                  value={formData.mainCatId}
                  onChange={(e) => handleMainCat(e.target.value)}
                  required
                >
                  <option value={`${Environment.defaultValue}`} disabled>
                    Choose Main Menu
                  </option>
                  {MenuList?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.mainCatName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.mainCatId}
                </Form.Text>
              </div>
            </Col>

            <Col xl="6">
              <div className="mb-3">
                <Form.Label className="fs-12 fw-semibold">
                  Sub Menu: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                size="sm"
                 className="text-dark"
                  aria-label="Default select example"
                  value={formData.catId}
                  onChange={(e) => {
                    setFormData({ ...formData, catId: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      catId: "",
                    }));
                  }}
                >
                  <option value={`${Environment.defaultValue}`} disabled>
                    Choose Sub Menu
                  </option>
                  {Category?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.catId}</Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <Form.Label className="fs-12 fw-semibold">
                Food Type: <sup className="text-danger">*</sup>
              </Form.Label>
              <div className="mb-3">
                <Form.Select
                size="sm"
                 className="text-dark"
                  aria-label="Default select example"
                  value={formData.foodtype}
                  name="foodtype"
                  onChange={handleFoodType}
                  required
                >
                  <option value={`${Environment.defaultValue}`} disabled>
                    Choose Food Type
                  </option>
                  {FoodType?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.foodtype}</Form.Text>
              </div>
            </Col>
            {showSubTyppe && (
              <Col xl="6">
                <Form.Label className="fs-12 fw-semibold">
                  Sub Food Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <div className="mb-3">
                  <Form.Select
                  size="sm"
                   className="text-dark"
                    aria-label="Default select example"
                    value={formData.foodSubType}
                    name="foodSubType"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        foodSubType: e.target.value,
                      });
                    }}
                  >
                    <option value={`${Environment.defaultValue}`} disabled>
                      Sub Food Type
                    </option>
                    {FoodSubType?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.fieldValue}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-danger">
                    {errors.foodSubType}
                  </Form.Text>
                </div>
              </Col>
            )}
          </Row>
          <hr></hr>
          <Row>
            <Col xl="12">
              <Row className="pr-0">
                <Col xl="6 pr-0">
                  <div className="mb-3">
                    <Form.Check
                    size="sm"
                    type="switch"
                      className="me-3 fs-14 fw-semibold mt-2 text-dark"
                      checked={formData.isTaxFree}
                      name="isTaxFree"
                      label={
                        formData.isTaxFree
                          ? "Tax Applicable"
                          : "Tax Not Applicable"
                      }
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isTaxFree: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl="12">
              <Form.Check
                type="switch"
                size="sm"
                className="me-3 fs-14 fw-semibold text-dark"
                checked={formData.isKOT}
                name="isKOT"
                label={
                  formData.isKOT ? "Send to kitchen for order processing" : "Not Sent to Kitchen"
                }
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl="12">
              <Form.Check
                type="switch"
                size="sm"
                className="me-3 fs-14 fw-semibold text-dark"
                checked={formData.isDaySpecial}
                name="isDaySpecial"
                label={
                  formData.isDaySpecial
                    ? "Available only on:"
                    : "Available on all days of the week"
                }
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    isDaySpecial: e.target.checked,
                  });
                }}
              />
            </Col>
          </Row>
          {formData.isDaySpecial && (
            <Row>
              <Col xl="6">
                <div onBlur={closeDropdown}>
                <Form.Label className="fs-12 fw-semibold">
                  Choose the days: <sup className="text-danger">*</sup>
                </Form.Label>
                  <div onClick={toggleDropdown}>
                    <Select
                      options={selectDaysOptions}
                      isSearchable={true}
                      value={formData.catalogueOnDays}
                      isMulti
                      menuIsOpen={isOpen}
                      onMenuClose={() => {}}
                      onChange={(selectedOptions) => {
                        setFormData({
                          ...formData,
                          catalogueOnDays: selectedOptions,
                        });
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14"
            type="submit"
            onClick={onSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Item</span>
          </Button>
        </div>
      </Offcanvas>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#289c34",
            color: "#fff",
            width: "100rem",
          },
        }}
      />
    </React.Fragment>
  );
}
