import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../../assets/img/login/side-patch.png";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import LoadingSpinner from "./../_common/LoadingSpinner";
import whiteLogo from "../../assets/img/login/fe-white-logo.png";
export default function LoginComponent() {
  const [loading, setLoading] = useState(false);
  const id = window.location.href;
  const domain = new URL(id).hostname;
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    domain: domain === "localhost" ? "ad.fudeasy.com" : domain,
  });
  const [loginError, setNewError] = useState();
  const { login } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        await login(formData);
      } catch (error) {
        setNewError("Invalid User name and password");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setNewError("");
  };

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      username: "",
      password: "",
    };
    if (!formData.username.trim()) {
      newErrors.username = "Username is required";
      isValid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Sign In</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please Signin to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-danger">
                    {errors.username}
                  </Form.Text>
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password{" "}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    placeholder="Enter your password"
                    onChange={handleChange}
                  />
                  <Form.Text className="text-danger">
                    {errors.password}
                  </Form.Text>
                  <Form.Text className="text-danger">
                    {loginError ? `${loginError} ` : ""}
                  </Form.Text>
                </div>

                <Button type="submit" className="btn-sign" disabled={loading}>
                  {loading ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Sign In"
                  )}
                </Button>
                <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer>
              Unable to login to your account?{" "}
              <Link to="/account/signup">Get Help!</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <div className="login-msg">
            <div className="header-logo mb-3">
              <img className="wt-200" src={whiteLogo} alt="logo" />
            </div>

            <h1 className="text-white fw-semibold">Robust solution for <br /> smart & growing <br /> restaurant</h1>
          </div>
          <img src={bg1} className="h-100 w-100" alt="bg" />
        </Col>
      </Row>
    </div>
  );
}
