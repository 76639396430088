// react-packages
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
//context
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
//images
import selectBranchIcon from "../../assets/img/login/select-branch.png";
import mainLogo from "../../assets/img/main-logo.png";
//actions,selectors
import { loadBranch } from "../../application/actions/loaderAction";
import { getAllCommonFields } from "../../application/actions/accountAction";
import {
  GetPOSMenuDataByBranchId,
  GetWaiterListPOS,
} from "../../application/actions/posAction";
import {
  getBranchList,
  getLoading,
  getWaiterListPOSSuccess,
} from "../../application/selectors/indexSelector";
//components
import LoadingSpinner from "./../_common/LoadingSpinner";

//created a loader for displaying it besides select dropdown untill the branchList is loaded
const CustomDropdownIndicator = ({ loading, value, ...props }) => (
  <components.DropdownIndicator {...props}>
    {loading ? (
      <LoadingSpinner color="#dc3545" size={20} type={"TailSpin"} />
    ) : null}
  </components.DropdownIndicator>
);

export default function SelectBranchComponent() {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //accessing user data from local storage using context
  const { user, setBranch, setSelectedBranchData } = useContext(AuthContext);
  //fetching and storing branchList
  const branchList = useSelector(getBranchList);
  //getting loading and value of which api data is loading
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  //making useState for selected branch
  const [selectedBranch, setSelectedBranch] = useState([]);
  //manipulating the branch list as needed in select package of react
  const branchListForDD = branchList?.map((option) => ({
    value: option.id,
    label: option.branchName,
  }));
  //fetching data from localstorage of menu for adding loader conditions
  const menuData = {
    menuList: localStorage.getItem("MenuList"),
    subMenuList: localStorage.getItem("SubMenuList"),
    catalogueList: localStorage.getItem("Catalogues"),
  };
  //fetching waiterlist for adding loader conditions
  const waiterListPOSSuccess = useSelector(getWaiterListPOSSuccess);
  //setting loader on onclick of submit button for adding loader conditions
  const [buttonLoader, setButtonLoader] = useState(false);

  //Load branch list based on tenant
  useEffect(() => {
    if (user?.tenanatId || branchList === undefined) {
      dispatch(loadBranch(user?.tenanatId));
    }
  }, [user?.tenanatId]);
  //Load common field list onload
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("CommonFields"))) {
      dispatch(getAllCommonFields);
    }
  }, []);
  //Navigating once all the data is fetched
  useEffect(() => {
    if (
      !(
        menuData.menuList === null ||
        menuData.subMenuList === null ||
        menuData.catalogueList === null
      ) &&
      !(waiterListPOSSuccess === undefined)
    ) {
      navigate("/");
    }
  }, [
    loading,
    menuData.catalogueList,
    menuData.menuList,
    menuData.subMenuList,
    navigate,
    value,
    waiterListPOSSuccess,
  ]);

  //Load menu data and waiter list based on branch selected and navigate the user to table state
  const handleSubmit = () => {
    setButtonLoader(true);
    dispatch(GetPOSMenuDataByBranchId(selectedBranch?.value));
    dispatch(GetWaiterListPOS(selectedBranch?.value));
    localStorage.setItem("branch", JSON.stringify(selectedBranch));
    localStorage.setItem(
      "selectedBranchData",
      JSON.stringify(
        branchList.find((branch) => branch?.id === selectedBranch?.value)
      )
    );
    setBranch(selectedBranch);
    setSelectedBranchData(
      JSON.stringify(
        branchList.find((branch) => branch?.id === selectedBranch?.value)
      )
    );
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/account/login" className="header-logo">
            <img className="wt-150" src={mainLogo} alt="logo" />
          </Link>
        </Container>
      </div>
      <div className="content">
        <Container>
          <Card className="card-auth wt-500">
            <Card.Body>
              <div className="mb-3 text-center">
                <img className="wt-80" src={selectBranchIcon} alt="icon" />
              </div>
              <Card.Title className="fs-14">
                Choose your branch / location to proceed:
              </Card.Title>
              <div className="mb-4">
                <Select
                  name="branchId"
                  options={branchListForDD}
                  onChange={(selectedOption) => {
                    setSelectedBranch(selectedOption);
                  }}
                  components={{
                    DropdownIndicator: (props) => (
                      <CustomDropdownIndicator
                        {...props}
                        loading={
                          (loading && value === "branchList") ||
                          branchList === undefined
                        }
                        value={value}
                      />
                    ),
                  }}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-100"
                  onClick={handleSubmit}
                  disabled={
                    ((loading && value === "posMenuData") ||
                      (loading && value === "waiterListPOS") ||
                      (menuData.menuList === null &&
                        menuData.subMenuList === null &&
                        menuData.catalogueList === null) ||
                      waiterListPOSSuccess === undefined) &&
                    buttonLoader
                  }
                >
                  {((loading && value === "posMenuData") ||
                    (loading && value === "waiterListPOS") ||
                    (menuData.menuList === null &&
                      menuData.subMenuList === null &&
                      menuData.catalogueList === null) ||
                    waiterListPOSSuccess === undefined) &&
                  buttonLoader ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Proceed"
                  )}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
