import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Form } from "react-bootstrap";
import "./kitchen.scss";
import AddNewKitchen from "./add-kitchen";
import EditKitchen from "./edit-kitchen";
import { useDispatch, useSelector } from "react-redux";
import { getKitchenList, getLoading } from "../../../../application/selectors/indexSelector";
import { loadKitchen } from "../../../../application/actions/loaderAction";
import { DeleteKitchen, GetKitchenById } from "../../../../application/actions/kitchenAction";
import DataTable from "react-data-table-component";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import { GetMenuList } from "../../../../application/actions/menuAction";

export default function KitchenGroups(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  //   const KitchenList = useSelector(getKitchenList);
  //   const loading = useSelector(state => state.Kitchen);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(loadKitchen(props?.branch?.id));
    dispatch(GetMenuList);
  }, []);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const KitchenList = useSelector(getKitchenList);

  const addIndexToData = (data) => {
    return data?.map((row, index) => ({ ...row, index: index + 1 }));
  };
  const KitchenListData = addIndexToData(KitchenList);
  const [showAddKitchen, setShowAddKitchen] = useState(currentSkin);
  const handleAddKitchen = (state) => {
    setShowAddKitchen(state);
  };
  const [editKitchen, setKitchenLid] = useState('');
  const [showEditKitchen, setshowEditKitchen] = useState(currentSkin);
  const handleEditKitchen = (item, state, tab) => {
    setshowEditKitchen(state);
    dispatch(GetKitchenById(item?.id));
    setKitchenLid(tab);
  };

  const deleteKitchen = (item, e) => {
    SweetAlertService.showAlert("Delete Kitchen", "Are you sure you want to delete this Kitchen?", "warning")
      .then((result) => {
        if (result.isConfirmed) {
          // SweetAlertService.showSuccess("Success", "Kitchen!");
          dispatch(DeleteKitchen(item));
          e.preventDefault();
          ToastifyService.success(`Kitchen Deleted Successfully`);
        } else {
          console.log("Delete operation cancelled!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.index,
    },
    {
      name: "Kitchen Name",
      selector: (row) => (
        <div>
          <h6>{row.kitchenName}</h6>
          <span className={`fs-10 default-branch ${row.branch === "Default" ? "d-inline" : ""}`}>{row.branch}</span>
        </div>
      ),
    },
    {
      name: "Associated Menus",
      selector: (row) => (
        <div className={`${row.associatedMenuCount === 0 ? "text-danger" : ""}`}>{row.associatedMenuCount}
          <Button variant={`outline-secondary setup-bttn fs-12`}
            className={`${row.associatedMenuCount === 0 ? "d-inline" : ""}`}
            onClick={() => handleEditKitchen(row, true, 'associated_menu_tab')}
            size="sm"
          >
            <i className="ri-pencil-line"></i> Setup
          </Button>
        </div>
      ),
    },
    {
      name: "Items Impacted",
      selector: (row) => (
        <div className={`${row.itemMappedCount === 0 ? "text-danger" : ""}`}>{row.itemMappedCount}</div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end dropdown">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditKitchen(row, true, 'details_tab')}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="danger"
            className="btn-icon me-2"
            onClick={(e) => deleteKitchen(row, e)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
          {/* <Dropdown>
            <Dropdown.Toggle
              variant="dark"
              className="btn-icon custom-dropdown-action"
              id="dropdown-basic"
            >
              <i className="ri-more-2-fill"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#">Action</Dropdown.Item>
              <Dropdown.Item href="#">Default</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      ),
      width: "150px",
    },
  ];


  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = KitchenListData?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <>
        <AddNewKitchen
          show={showAddKitchen}
          branch={props?.branch}
          closeFunction={handleAddKitchen}
        />
        <EditKitchen
          branch={props?.branch}
          show={showEditKitchen}
          tabData={editKitchen}
          // data={editKitchen}
          closeFunction={() => setshowEditKitchen(false)}
        />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2 mt-3 mt-md-0">

          </div>
          {/* <div>
            <h4 className="main-title fs-14 fw-bold mb-0">Kitchen KOT Groups</h4>
          </div>
           */}
        </div>

        <Card className="card-one">
          <Card.Body className="p-2 p-xl-4">
            <div className="d-flex  justify-content-between mb-3">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Kitchen Group</h4>
              </div>
              <div className="d-flex custom-dropdown-wrapper">
                <Button
                  variant="danger"
                  size="sm"
                  className="d-flex align-items-center gap-2 me-4"
                  onClick={() => handleAddKitchen(true)}
                >
                  <i className="ri-add-line fs-14 lh-1"></i>
                  <span className="d-sm-inline">Add New Kitchens</span>
                </Button>
                <div className="custom-drop-down z-index-2 wt-250 ">
                  <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                  <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>

              </div>

            </div>
            {!loading && (
              <>
                {filteredEmployees && filteredEmployees !== undefined && (
                  <DataTable
                    columns={columns}
                    // rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                    data={filteredEmployees}
                    fixedHeader
                    pagination
                    search={true}
                    highlightOnHover
                    className="custom-common-table"
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </>
      {!loading && filteredEmployees === undefined && <TableShimmerLoader columnCount={4} rowCount={4} />}
      {loading && value === "kitchenList" && <TableShimmerLoader columnCount={4} rowCount={4} />}
    </React.Fragment>
  );
}
